/**
 * Text Only Hero : use text only hero for content that warrants the highest level of prominence but doesn’t require imagery.
 *
 * @module views/components/TextOnlyHero
 * @memberof -Common
 */
import './TextOnlyHero.scss';

import React from 'react';

import classNames from 'classnames';
import PropTypes from 'prop-types';

import GridContainer from '@ulta/core/components/GridContainer/GridContainer';
import Text from '@ulta/core/components/Text/Text';
import UltaAsset from '@ulta/core/components/UltaAsset/UltaAsset';

import Tag from '@ulta/modules/Tag/Tag';

import { highlightString } from '@ulta/utils/Formatters/Formatters';

import ActionGroup from '../ActionGroup/ActionGroup';

/**
 * Represents a TextOnlyHero component
 *
 * @method
 * @param {TextOnlyHeroProps} props - React properties passed from composition
 * @returns TextOnlyHero
 */
export const TextOnlyHero = function( props ){
  const {
    backgroundColor,
    image,
    tag,
    eyebrow,
    headline,
    headlineTag,
    headlineStyle,
    headlineHighlight,
    headlineHighlightColor,
    subheadline,
    subheadlineStyle,
    actionGroup
  } = props;
  if( !headline ){
    return null;
  }
  return (
    <div className={ classNames( 'TextOnlyHero', {
      'TextOnlyHero__withBackgroundColor': backgroundColor
    } ) }
    >
      <GridContainer fullBleedContentBackgroundColor={ backgroundColor }>
        { image && (
          <div className='TextOnlyHero__image'>
            <UltaAsset
              { ...( image ) }
            />
          </div>
        ) }
        { tag && (
          <div className='TextOnlyHero__tag'>
            <Tag
              style='default'
              message={ tag }
            />
          </div>
        ) }
        { eyebrow && (
          <div className='TextOnlyHero__eyebrow'>
            <Text
              htmlTag='span'
              textStyle='eyebrow'
            >
              { eyebrow }
            </Text>
          </div>
        ) }
        { headline && (
          <div className={
            classNames( 'TextOnlyHero__headline', {
              [`TextOnlyHero__headlineHighlightColor--${headlineHighlightColor}`]:headlineHighlightColor
            } )
          }
          >
            <Text
              htmlTag={ headlineTag }
              textStyle={ headlineStyle }
              textAlign='center'
            >
              { highlightString( headline, headlineHighlight ) }
            </Text>
          </div>
        ) }
        { subheadline && (
          <div className='TextOnlyHero__subheadline'>
            <Text
              htmlTag='p'
              textStyle={ subheadlineStyle }
              textAlign='center'
            >
              { subheadline }
            </Text>
          </div>
        ) }
        { actionGroup?.action1?.label && (
          <div className='TextOnlyHero__actions'>
            <ActionGroup
              action1={ actionGroup.action1 }
              action1Style={ actionGroup.action1Style }
              ariaLabel={ headline }
            />
          </div>
        ) }
      </GridContainer>
    </div>
  );
};

/**
 * Property type definitions
 * @typedef TextOnlyHeroProps
 * @type {object}
 * @property {string} backgroundColor - Set the full bleed background color
 * @property {object} Image - Sets the  image
 * @property {string} Tag - Sets the Tag
 * @property {string} eyebrow - Sets the eyebrow text
 * @property {string} headline  - Sets the headline  text
 * @property {string} headlineTag - Sets the headlineTag text
 * @property {string} headlineHighlight - Sets the headlineHighlight
 * @property {string} headlineHighlightColor - Sets the healineHighlightColor
 * @property {string} subheadline - Sets the subheadline
 * @property {object} actionGroup - Set the actionGroup
 * @property {string} subheadlineStyle - Sets the subtitle style value
 * @property {string} headlineStyle - Sets the headline Style value
 */
export const propTypes = {
  /** Set the full bleed background color. */
  backgroundColor: PropTypes.string,
  /** Sets the  image */
  image: PropTypes.object,
  /** Sets the eyebrow text */
  eyebrow: PropTypes.string,
  /** Sets the Tag */
  tag: PropTypes.string,
  /** Sets the headline  text */
  headline: PropTypes.string,
  /** Sets the headlineTag text */
  headlineTag: PropTypes.string,
  /** Sets the headlineHighlight for display */
  headlineHighlight: PropTypes.string,
  /** Sets the headlineHighlightColor for display */
  headlineHighlightColor: PropTypes.string,
  /** Set the subheadline for display */
  subheadline: PropTypes.string,
  /** Set the action1 */
  actionGroup: PropTypes.object,
  /** Sets the subtitle style value */
  subheadlineStyle: PropTypes.string,
  /** Sets the headline Style value */
  headlineStyle: PropTypes.string
};

/**
 * Default values for passed properties
 * @type object
 * @property {string} headlineTag='h2' - Default headlineTag is h2
 * @property {string} subheadlineStyle='subtitle-1' - Sets the default subtitle style value
 * @property {string} headlineStyle='title-2' - Sets the default headline style value
 */
export const defaultProps = {
  headlineTag: 'h2',
  subheadlineStyle: 'subtitle-1',
  headlineStyle: 'title-2'
};

TextOnlyHero.defaultProps = defaultProps;
TextOnlyHero.propTypes = propTypes;

export default TextOnlyHero;
